import { Button, Carousel, Col, Container, Row, Stack } from "react-bootstrap"

const ArticleDetail = () => {
 
    return <div>
      {/* <Stack direction="horizontal" gap={2}>
      </Stack> */}

      <Container className="article-detail-wrapper mt-5">
        <Row>
          <Col>
            <Container fluid>
              <Row>
                <Col sm="8" className="flex-wrap">
                  <h6 className="article-cat">SHOP MY BAG:</h6>
                  <h2 className="article-title">CASSANDRA GREY'S GUIDE TO GIFTING: HOLIDAY EDITION</h2>

                  <hr className="one" />

                  <p className="article-excerpt">
                    What the founder of NOUR is shopping this season.
                  </p>

                  <div className="d-flex gap-4 article-credit-wrapper"><span><i className="credit-title">Written by:</i> CASSANDRA GREY</span><span><i className="credit-title">Written by:</i> CASSANDRA GREY</span></div>
                  
                  <div className="mt-4 article-feature-image"><img src={"../assets/imgs/other/article-image.webp"} width={"100%"} /></div>

                  <div className="article-content-wrapper">
                    <h3>In celebration of Asian American Pacific Islander Heritage Month, we at VIOLET GREY are proud to spotlight the exceptional talent of our Committee, our brand founders, and our VG community. If there’s one thing makeup artist Daniel Martin is known for, it’s creating looks that spotlight otherworldly skin. Think Ali Wong’s winning fresh-faced glamour at the Golden Globes and SAG Awards, Nicola Coughlan’s red carpet reign while promoting the latest season of Bridgerton, and the soft romantic wedding look he created for one of his best friends, Meghan, Duchess of Sussex—a history-defining moment that put the already sought-after editorial artist squarely on the map (and every talk show). In his pursuit of complexion perfection, Martin has become rather product-obsessed, so much so that he develops them for brands like Tatcha, and will stop you in the middle of a Zoom call to ask what blush you’re wearing. Fresh off the A100 Gold Gala—where he was honored as an impactful Asian Pacific leader in culture and society for 2024—Daniel stopped by The Store on Melrose Place to do a little shopping. Below, the best-in-class items he added to THE BAG.</h3>
                    <h4>NATHANIEL HAWKINS: WHEN DID YOU FIRST REALIZE THAT YOU HAD A TALENT FOR HAIR?</h4>
                    <p>GARREN: I was just obsessed with hair at the age of 13. My mother used to go to the beauty salon and, being that she was prematurely gray in her thirties, they would send her home with the Queen Elizabeth hairstyle—all little waves and dips. I would brush it all out and tease it up because I wanted her to look like Elizabeth Taylor or Catherine Deneuve or any of those famous movie stars that I adored back in the day.</p>

                    <figure>
                      <img src={"../assets/imgs/other/article-detail-image2.webp"} width={"100%"} />
                      <figcaption>GARREN'S FAMOUS “CHOP” FOR KARLIE IN VOGUE. PHOTOGRAPHED BY PATRICK DEMARCHELIER / TRUNK ARCHIVE.</figcaption>
                    </figure>

                    <blockquote> Any inaccuracies in this index may be explained by the fact that it has been sorted with the help of a computer.<br /> &mdash; from <cite>The Art of Computer Programming</cite> by Donald Knuth </blockquote>

                  </div>
                </Col>
                <Col sm="4" className="article-sidebar flex-wrap">
                  <div className="article-share-div">
                    <p>SHARE THIS</p>
                    <div className="d-flex flex-row icon-wrapper">
                      <a href="#"><svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0z" fill="currentColor"></path><path className="fill-black" fill-rule="evenodd" clip-rule="evenodd" d="M20 6.286H5v10.741h15V6.287zm-7.5 4.626L7.487 7.526h10.157L12.5 10.912zm-6.26 4.876V8.179l6.25 4.223 6.27-4.126v7.512H6.24z" fill="#fff"></path></svg></a>
                      <a href="#"><svg width="1em" height="1em" fill="none" viewBox="0 0 24 24"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm3 8h-1.35c-.538 0-.65.221-.65.778V10h2l-.209 2H13v7h-3v-7H8v-2h2V7.692C10 5.923 10.931 5 13.029 5H15v3Z" fill="currentColor"></path></svg></a>
                      <a href="#"><svg width="1em" height="1em" fill="none" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.373 0 0 5.372 0 12c0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738a.36.36 0 0 1 .083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146C9.57 23.812 10.763 24 12 24c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12Z" fill="currentColor"></path></svg></a>
                      <a href="#"><svg width="1em" height="1em" fill="none" viewBox="0 0 24 24"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544A8.127 8.127 0 0 1 5.5 16.898a5.778 5.778 0 0 0 4.252-1.189 2.879 2.879 0 0 1-2.684-1.995 2.88 2.88 0 0 0 1.298-.049c-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359a2.877 2.877 0 0 1-.889-3.835 8.153 8.153 0 0 0 5.92 3.001 2.876 2.876 0 0 1 4.895-2.62 5.73 5.73 0 0 0 1.824-.697 2.884 2.884 0 0 1-1.263 1.589 5.73 5.73 0 0 0 1.649-.453 5.765 5.765 0 0 1-1.433 1.489Z" fill="currentColor"></path></svg></a>
                    </div>
                  </div>
                  <hr className="one" />
                  <div className="brand-name">
                    <h5>NOUR</h5>
                    <p className="mb-0 siderbar-label">FILED UNDER</p>
                    <p className="cat-name">LESSONS</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <div className="article-product-main-wrapper">
                    <h2>SHOP MY BAG</h2>
                    <hr className="heading-hr" />

                    {[0,0,0,0].map((item, index) => {
                      return (
                        <Row className="product-row-wrapper">
                          <Col sm="4" className="align-self-end">
                            <div className="numbers-container">
                              <span className="number-one">{index + 1}</span>
                              <span className="number-devider">/</span>
                              <span className="number-two">4</span>
                            </div>
                            <div className="product-description">
                              “This is my pick-me-up mask when I need a boost post-travel, pre-event, or when lack of sleep shows up in my skin. As with most of my favorite brands, it combines clean, sustainably sourced, botanical ingredients with clean science that excludes petrochemicals and unnecessary additives. Plus, I love the use of refillable and recyclable packaging to minimize waste.”
                            </div>
                            <div className="product-brand-name">AUGUSTINUS BADER</div>
                            <div className="product-name">THE FACE CREAM MASK</div>
                            <div className="price-product">$<span className="main-price">85.00</span></div>
                            <a className="shop-now-btn" href="#">Shop Now</a>
                          </Col>
                          <Col xs="12" sm="8" className="product-media mt-3 d-flex align-items-center flex-column">
                            <Carousel className="w-sm-50">
                              <Carousel.Item>
                                <img src="../assets/imgs/other/product-feature-img-1.jpg" width={"100%"} />
                              </Carousel.Item>
                              <Carousel.Item>
                                <img src="../assets/imgs/other/product-feature-img-1.jpg" width={"100%"} />
                              </Carousel.Item>
                              <Carousel.Item>
                                <img src="../assets/imgs/other/product-feature-img-1.jpg" width={"100%"} />
                              </Carousel.Item>
                            </Carousel>
                            <hr className="mb-0 w-100" />
                          </Col>
                        </Row>
                      )
                    })}
                    
                  </div>
                </Col>
              </Row>
              

              <Row>
                <Col className="article-share-btn-footer">
                  <div className="d-flex flex-row icon-wrapper">
                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0z" fill="currentColor"></path><path className="fill-black" fill-rule="evenodd" clip-rule="evenodd" d="M20 6.286H5v10.741h15V6.287zm-7.5 4.626L7.487 7.526h10.157L12.5 10.912zm-6.26 4.876V8.179l6.25 4.223 6.27-4.126v7.512H6.24z" fill="#fff"></path></svg>
                    <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm3 8h-1.35c-.538 0-.65.221-.65.778V10h2l-.209 2H13v7h-3v-7H8v-2h2V7.692C10 5.923 10.931 5 13.029 5H15v3Z" fill="currentColor"></path></svg>
                    <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.373 0 0 5.372 0 12c0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738a.36.36 0 0 1 .083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146C9.57 23.812 10.763 24 12 24c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12Z" fill="currentColor"></path></svg>
                    <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544A8.127 8.127 0 0 1 5.5 16.898a5.778 5.778 0 0 0 4.252-1.189 2.879 2.879 0 0 1-2.684-1.995 2.88 2.88 0 0 0 1.298-.049c-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359a2.877 2.877 0 0 1-.889-3.835 8.153 8.153 0 0 0 5.92 3.001 2.876 2.876 0 0 1 4.895-2.62 5.73 5.73 0 0 0 1.824-.697 2.884 2.884 0 0 1-1.263 1.589 5.73 5.73 0 0 0 1.649-.453 5.765 5.765 0 0 1-1.433 1.489Z" fill="currentColor"></path></svg>
                  </div>
                </Col>
              </Row>

              <hr className="two" />

              <Row className="next-prev-article">
                <Col>
                  <div className="d-flex gap-3">
                    <img src={"../assets/imgs/other/next-article-2.webp"} width="40%" />
                    <div>
                      <p className="mb-2"><i>filed under</i> SHOP MY BAG</p>
                      <h3>BETINA GOLDSTEIN</h3>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex gap-3">
                    <img src={"../assets/imgs/other/next-article-1.webp"} width="40%" />
                    <div>
                      <p className="mb-2"><i>filed under</i> SHOP MY BAG</p>
                      <h3>BETINA GOLDSTEIN</h3>
                    </div>
                  </div>
                </Col>
              </Row>
              
            </Container>
          </Col>
        </Row>
      </Container>
        {/* <h1>Hello ArticleDetailpage</h1> */}
    </div>
}

export default ArticleDetail
