import { useState } from "react";
import { Button, Carousel, Col, Container, Row, Stack } from "react-bootstrap"

const Home = () => {

  const [categories, setCategories] = useState([
    {name: "Interviews"},
    {name: "Shop Their Bag"},
    {name: "Reviews"},
    {name: "Lessons"},
    {name: "Violet TV"}
  ]);

  // Example items, to simulate fetching from another resources.
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  function Items( currentItems: any ) {
    return (
      <>
        {currentItems &&
          currentItems.map((item: any) => (
            <div>
              <h3>Item #{item}</h3>
            </div>
          ))}
      </>
    );
  }

  return <div>
  <Container fluid>
    <Row className="main-slider">
      <Col xs="12" sm="12" className="p-0 m-0 d-flex align-items-center flex-column">
        <Carousel className="carousel-container">
          {[0,0,0,0].map(() => {
            return(
            <Carousel.Item>
              <div className="images-carousel">
                <img src="../assets/imgs/other/product-feature-img-1.jpg" width={"100%"} />
                <img src="../assets/imgs/other/product-feature-img-1.jpg" width={"100%"} />
              </div>
              <div className="carousel-slide">
                <div className="w-50"></div>
                <div className="carousel-content">
                  <svg width="15.54em" height="1em" fill="none" viewBox="0 0 202 13" className="css-1p57l9y eq2o5c07"><title>Violet Files</title><g clip-path="url(#a)" fill="currentColor"><path d="M19.74.156 16.7 9.055 13.663.156h-3.24l4.733 12.685h3.11L22.98.156h-3.24ZM34.209.156h-2.98v12.685h2.98V.156ZM49.704 0c-3.829 0-6.72 2.78-6.72 6.488 0 3.708 2.886 6.489 6.72 6.489 3.834 0 6.72-2.78 6.72-6.489 0-3.707-2.89-6.488-6.72-6.488Zm3.74 6.488c0 2.318-1.573 4.014-3.74 4.014-2.166 0-3.74-1.687-3.74-4.014 0-2.326 1.574-4.013 3.74-4.013 2.167 0 3.74 1.696 3.74 4.023v-.01ZM68.082 10.352V.156h-2.98v12.685h9.226v-2.489h-6.246ZM85.711 10.352V7.48h5.307V4.99h-5.307V2.64h6.57V.156h-9.55v12.685h9.645v-2.489H85.71ZM133.31 2.64V.156h-9.117v12.685h2.981V7.48h4.887V4.99h-4.887V2.64h6.136ZM144.961.156h-2.981v12.685h2.981V.156ZM100.238.156V2.64h4.149v10.201h2.981v-10.2h4.153V.155h-11.283ZM158.316 10.352V.156h-2.98v12.685h9.226v-2.489h-6.246ZM175.939 10.352V7.48h5.302V4.99h-5.302V2.64h6.566V.156h-9.546v12.685h9.64v-2.489h-6.66ZM196.807 5.175c-1.847-.162-2.995-.315-2.995-1.39s1.388-1.316 2.551-1.316c1.598 0 2.526.676 2.681.926l.065.102 2.496-1.325-.06-.107c-.05-.083-1.253-2.08-5.192-2.08-3.495 0-5.526 1.39-5.526 3.8 0 3.402 2.88 3.652 5.197 3.851 1.842.158 2.995.31 2.995 1.39 0 .408 0 1.493-2.381 1.493-2.382 0-3.595-.885-3.924-1.39l-.065-.107-2.417 1.316.065.107c.754 1.177 2.497 2.581 6.381 2.581 2.571 0 5.322-1.043 5.322-3.976 0-3.42-2.881-3.676-5.193-3.875ZM.805 11V9.835h-.8v3.152h.8v-1.173H3.71v-.816H.805ZM3.7 5.71v-.812H0v.811h1.393v1.479H0v.811h3.7v-.811H2.211V5.709h1.487ZM2.941.172v1.807h-.733V.237h-.77v1.742H.806V.19h-.8v2.6H3.71V.172h-.769Z"></path></g></svg>
                </div>
              </div>
            </Carousel.Item>
          )})}
        </Carousel>
      
      </Col>
    </Row>
    <hr className="mb-0 w-100" />
    <Row className="home-body">
      <Col sm={3}>
        <div className="archive-sidebar">
          <h4>NOUR</h4>
          <p className="sidebar-label">filed under</p>
          <ul>
            {categories?.map((item) => {
              return <li><a href="/articles">{item?.name}</a></li>
            })}
          </ul>
          <hr className="heading-hr" />
        </div>
      </Col>
      <Col sm={9}>
        <div className="archive-main-content">
          {[0,0,0,0,0,0,0,0,0]?.map(() => {
            return (
              <div className="article-list-container">
                <a href="/article-detail" className="left-art-container"><img src={"../assets/imgs/other/article-list-feature-image.webp"} width={"50%"} /></a>
                <div className="article-meta-container">
                  <div className="cat-container"><i>Filed Under</i><p className="list-cat">SHOP MY BAG</p></div>
                  <a href="/article-detail" className="article-title-link"><h3 className="article-heading">EIGHTH DAY'S REJUVENATING MOISTURIZING PRIMER SPF 30</h3></a>
                  <p className="article-desc">The product-obsessed makeup artist known for complexion perfection.</p>
                  <a href="/article-detail" className="article-btn">READ & SHOP THE FILE</a>
                </div>
              </div>
            )
          })}
          
          <Row className="pt-5 justify-content-center d-flex w-100">
            <Col sm="12">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className="page-item disabled">
                    <a className="page-link">Previous</a>
                  </li>
                  []
                  <li className="page-item"><a className="page-link" href="#">1</a></li>
                  <li className="page-item"><a className="page-link" href="#">2</a></li>
                  <li className="page-item"><a className="page-link" href="#">3</a></li>
                  <li className="page-item">
                    <a className="page-link" href="#">Next</a>
                  </li>
                </ul>
              </nav>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  </Container>
</div>
}
export default Home
