import { Outlet } from "react-router-dom";
import Header from "./global-components/header";
import Footer from "./global-components/footer"
import 'bootstrap/dist/css/bootstrap.css';
import UpperFooter from "./global-components/upper-footer";
import { useEffect, useState } from "react";
import ModalComponent from "./global-components/modal";

const Layout = () => {


    return <div>
        <Header />
        <main>    
            <Outlet />
        </main>
        <UpperFooter />
        <Footer /> 
    </div>
}

export default Layout
