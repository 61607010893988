import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalComponent = (props: any) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal-container"
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <Container>
            <Row>
                <Col sm="6" className="image-column"></Col>
                <Col sm="6" className="modal-content-wrapper">
                    <h4>NOUR</h4>
                    <h5>TAKE 15% OFF YOUR FIRST ORDER</h5>
                    <form>
                        <input name="myInput" />
                        <button type="submit" className="submit-btn">Subscribe</button>
                    </form>
                    <p className="note-text">By registering you agree to accept the Terms & Conditions and have read the Privacy Policy. Opt out at any time by clicking Unsubscribe at the bottom of any of our emails.</p>
                </Col>
            </Row>
            
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalComponent;