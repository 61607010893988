import { useState } from "react";
import { Col, Row } from "react-bootstrap";

const UpperFooter = () => { 

    const [email, setEmail] = useState("");

    const handleSubmit = (value : any) => {
        return console.log(value)
    }

    return <Row className="upper-footer-container justify-content-center align-items-center">
        <Col sm={3} className="">
            <h2>SUBSCRIBE <br />TO OUR <br />NEWSLETTER</h2>
            <p>Sign-up to receive 15% off on your first order. <a href="/en-us/legal/welcome-offer">T&amp;Cs apply</a>.</p>
        </Col>
        <Col sm={4}>
            <form onSubmit={handleSubmit}>
                <label>
                {/* Name: */}
                <input type="text" value={email} onChange={(e) => setEmail(e?.target?.value)} />
                </label>
                <input type="submit" value="Submit" />
            </form>
            <p className="mt-4">By registering you agree to accept the <a href="/en-us/legal/terms-conditions" target="_blank">Terms &amp; Conditions</a> and have read the&nbsp;<a href="/en-us/legal/privacy-policy">Privacy Policy</a>. Opt out at any time by clicking<em> Unsubscribe</em> at the bottom of any of our emails.</p>
        </Col>
    </Row>
}

export default UpperFooter;