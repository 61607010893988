import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, RouterProvider, Routes, createBrowserRouter } from 'react-router-dom';
import Home from './components/home';
import About from './components/about';
import Layout from './components/layout';
import Page404 from './components/page404';
import ArticleDetail from './components/article-detail';
import Articles from './components/articles';

// function App() {


//   const router = createBrowserRouter([
//     {
//     element: <Layout />,
//     // your custom routing error component
//     errorElement: <Page404 />,
//     children: [
//       { path: "/",element: <Home /> },
//       { path: "/about", element: <About /> },
//       { path: "/article-detail", element: <ArticleDetail /> },
//       { path: "/articles", element: <Articles /> },
//     ],
//     }
//   ])

interface MyComponentProps {
  title: string;
}

  const App = () =>  {
    
 
        return(
                  <BrowserRouter basename="/">
	           
                    <Routes>
                        {/* <Switch> */}
                        <Route element={<div> <Layout /> </div>}>
                            <Route  path="/" element={<Home />} />
                            <Route path="/articles" element={<Articles />} />
                            <Route path="/article-detail" element={<ArticleDetail />} />
                            {/* <Route path="/article-detail/:slug" element={<ArticleDetail />} /> */}
                            {/* <Route path="*" component={Error} /> */}
                        {/* </Switch> */}
                        </Route>
                    </Routes>
	            
                  </BrowserRouter> 
              
        )
}

  // return (
  //   // <BrowserRouter basename="/aiwa/">
  //   //   <div>
  //   //     <Switch>
  //   //       <Route path='/' component={() => <Home />} exact />
  //   //       <Route path='/about' component={() => <About />} exact />
  //   //       <Route path='/article-detail' component={() => <ArticleDetail />} exact />
  //   //       <Route path='/articles' component={() => <Articles />} exact />
  //   //     </Switch>
  //   //   </div>
  //   // </BrowserRouter>
  //     <RouterProvider router={router} />
  // )

  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.tsx</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
// }

export default App;
