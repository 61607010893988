import { Button, Carousel, Col, Container, Row, Stack } from "react-bootstrap"

const Articles = () => {
 
    return <div>
      {/* <Stack direction="horizontal" gap={2}>
      </Stack> */}

      <Container fluid>
        <Row>
          <Col sm="12" className="flex-wrap">
            <img src={"../assets/imgs/other/page-title.webp"} width={"100%"} />
          </Col>
        </Row>
      </Container>
      <Container fluid className="article-archive-wrapper">
        <Row>
          <Col>
            <h1 className="article-archive-heading">REVIEWS</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={3} className="px-0">
            <div className="archive-sidebar">
              <h4>NOUR</h4>
              <p className="sidebar-label">filed under</p>
              <ul>
                {[0,0,0,0].map(() => {
                  return <li><a href="/articles">INTERVIEWS</a></li>
                })}
              </ul>
              <hr className="heading-hr" />
            </div>
          </Col> 
          <Col sm={9} className="px-0">
            <div className="archive-main-content">
              {[0,0,0,0,0,0,0,0,0]?.map(() => {
                return (
                  <a href="/article-detail" className="article-grid-container">
                    <img src={"../assets/imgs/other/article-list-feature-image.webp"} width={"100%"} />
                    <h3 className="article-heading">EIGHTH DAY'S REJUVENATING MOISTURIZING PRIMER SPF 30</h3>
                  </a>
                )
              })}
             
              {/* <Row className="px-10">
                <Col sm="4">
                 
                </Col>
              </Row> */}
            </div>
          </Col> 
        </Row>
      </Container>
        {/* <h1>Hello ArticleDetailpage</h1> */}
    </div>
}

export default Articles
